<template>
	<div>
		<!-- 顶部信息 -->
		<div class="headerbox">
			<el-row :gutter="20">
				<el-col :span="8">
					<img :src="talentData.image" style="width: 100%;height: 350px;border-radius: 20px;" @error="itemError(talentData)"/>
				</el-col>
				<el-col :span="16">
					<el-row style="text-align: left;">
						<el-col :span="24">
							<span class="spantitle">{{talentData.name}}</span>
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
							<span v-if="talentData.isStart==0" class="spanstatus1">可预约</span>
							<span v-if="talentData.isStart==1" class="spanstatus2">可报名</span>
							<span class="spanslast">上次开课：{{talentData.previous}}</span>
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
							<span class="pteacher">培训老师:{{talentData.teacher}}</span>
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
              <span v-if="talentData.classification == '0'" class="pteacher">课程分类:学历提升</span>
              <span v-if="talentData.classification == '1'" class="pteacher">课程分类:技能证书培训</span>
              <span v-if="talentData.classification == '2'" class="pteacher">课程分类:职业素养提升</span>
<!--							<span v-if="recordData" class="pteacher">课程安排:{{recordData.address}}  {{recordData.courseStart}} 至 {{recordData.courseEnd}}</span>-->
<!--							<span v-else class="pteacher">课程安排:暂无</span>-->
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
							<p class="p1">参考费用</p>
							<p class="p2"><span class="spanpay" style="font-size: 14px;">¥</span><span class="spanpay">{{talentData.fee}}</span><span class="spanpayt">实际费用以线下交费时收取为准</span></p>
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
							<el-button v-if="talentData.isStart==0" class="btn" @click="toTalentAdd(talentData.id)">提交意向</el-button>
							<el-button v-if="talentData.isStart==1" class="btn" disabled="disabled">提交意向</el-button>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</div>
		<div class="contentbox">
			<el-tabs v-model="activeName" @tab-click="handleClick()">
          <el-tab-pane label="课程安排" name="content">
            <div class="talent">
              <div style="width: 100%;">
                <el-row :gutter="25">
                  <el-col :span="6" v-for="(item, index) in recordData" :key="index" >
                    <el-card :body-style="{ padding: '0px' }">
                      <el-image class="talentimg" :src="talentData.image" @click="toTalentAddRecord(item.id,item.indexDisplay)" @error="itemError(item)"></el-image>
                      <div style="margin-left: 16px;">
                        <p class="talentp1">{{ item.courseName }}</p>
                        <p class="talentp2">{{ item.courseStart }} 至 {{ item.courseEnd }}
                          <span v-if="item.indexDisplay == '1'" class="talentspan4">已开课</span>
                          <span v-if="item.indexDisplay == '2'" class="talentspan4">可报名</span>
                        </p>
                        <p class="talentp3">{{ item.address }}<span class="talentspan3">{{ item.teacher }}</span></p>
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-tab-pane>
			    <el-tab-pane label="课程介绍" name="first"><div style="text-align: left;" v-html="talentData.introduction"></div></el-tab-pane>
			    <el-tab-pane label="课程大纲" name="second"><div style="text-align: left;" v-html="talentData.outline"></div></el-tab-pane>
			  </el-tabs>
		</div>
	</div>
</template>

<script>
import { baseURL } from "@/utils/request/apis";
import defaultImage from "@/assets/images/home/default_companyLogo.png";
export default {
	name: "TalentInfo",
	data() {
		return {
			id:'',
			url:{
				getLzCourseUrl:"/lz_course/lzCourse/getById",
				getLzCourseRecordUrl:"/lz_course_record/lzCourseRecord/queryListByCourseId",
			},
			activeName:"content",
			talentData:{},
			recordData:{},
      defaultImage
		}
	},
	methods: {
		itemError(item){
      item.image = this.defaultImage;
		},
		// 获取基本信息
		getTalentData(){
			this.$http.get(baseURL+this.url.getLzCourseUrl, { id: this.id}).then((res) => {
				// console.log(res)
				this.talentData = res.result;
				this.talentData.image = baseURL + '/' + this.talentData.image;
			});
		},
		// 获取课程信息
		getRecordData(){
			this.$http.get(baseURL+this.url.getLzCourseRecordUrl, { courseId: this.id}).then((res) => {
				// console.log(res);
				this.recordData = res.result;
			});
		},
		handleClick(tab, event) {
			// console.log(tab, event);
		},
		toTalentAdd(id){
			const url = this.$router.push({ name: 'TPTalentAdd', query: { id: id } });
		},
    toTalentAddRecord(id,indexDisplay){
		  if(indexDisplay == "1"){
		    this.$message.error("已开课无法报名");
      }else{
        const url = this.$router.push({ name: 'TPTalentAddRecord', query: { id: id } });
      }
    }
	},
	created() {
		this.id = this.$route.query.id;
		this.getTalentData();
		this.getRecordData();
	}
}
</script>

<style scoped>
.headerbox {
	padding: 20px;
	margin: 20px auto;
  width: 1200px;
	margin-bottom: 20px;
	border-radius: 30px;
	background-color: #FFFFFF;
}
.contentbox {
	padding: 20px;
  margin: 20px auto;
  width: 1200px;
	margin-bottom: 20px;
	border-radius: 30px;
	background-color: #FFFFFF;
}
.spantitle {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
}
.spanstatus1 {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	border-radius: 5px;
	background-color: #497BFD;
	color: #FFFFFF;
}
.spanstatus2 {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	border-radius: 5px;
	background-color: #FDB549;
	color: #FFFFFF;
}
.spanslast {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
}
.pteacher {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.p1 {
	margin: 0px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: left;
	background-color: #FF7078;
	color: #FFFFFF;
	width: 400px;
}
.p2 {
	margin: 0px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #f0f0f0;
	color: #FF434E;
	width: 400px;
}
.spanpay {
	text-align: left;
	width: 120px;
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
}
.spanpayt {
	margin-left: 100px;
	color: #909399;
	width: 280px;
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
}
.btn {
	width: 120px;
	background-color: #00CF97;
	color: #FFFFFF;
}

.talent {
  margin: 20px auto;
  width: 1200px;
}
.talentimg {
  width: 100%;
  height: 170px;
}

.talentp1 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  text-align: left;
  margin-left: 5px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.talentp2 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 200;
  font-size: 13px;
  text-align: left;
  margin-left: 5px;
}

.talentp3 {
  font-family: 'PingFang SC';
  font-style: normal;
  text-align: left;
  margin-left: 5px;
}
.talentspan1 {
  color: #FF434E;
  font-size: 14px;
  font-weight: 500;
}

.talentspan2 {
  color: #FF434E;
  font-size: 20px;
  font-weight: 500;
}

.talentspan3 {
  margin-left: 10px;
  background-color: #FFF2E6;
  color: #C35424;
  font-size: 18px;
  font-weight: 500;
}

.talentspan4 {
  margin-left: 10px;
  color: #C35424;
  font-size: 18px;
  font-weight: 500;
}
.talentspan5 {
  color: #C35424;
  font-size: 18px;
  font-weight: 500;
}
</style>