import { render, staticRenderFns } from "./TalentInfo.vue?vue&type=template&id=70a4ccab&scoped=true"
import script from "./TalentInfo.vue?vue&type=script&lang=js"
export * from "./TalentInfo.vue?vue&type=script&lang=js"
import style0 from "./TalentInfo.vue?vue&type=style&index=0&id=70a4ccab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a4ccab",
  null
  
)

export default component.exports